import { PortableText } from "@portabletext/react";
import { Button, Footer, Header, Text } from "@taskalliance/lms";
import { settingsQuery } from "@utils/groqQueries";
import { getClient } from "libs/sanity-library/src/lib/sanity.server";
import Head from "next/head";
import { useRouter } from "next/router";
import { groq } from "next-sanity";
import { FunctionComponent } from "react";
import useViewPort from "@hooks/useViewPort";
import { ParsePageLanguage } from "@taskalliance/sanity-library/Languages";
import styles from "./index.module.scss";

const landingPageQuery = groq`
  *[_type in ["landingPage"]] {
    topTitle,
    pageImage {
      alt,
      "url": asset->url,
      "width": asset->metadata.dimensions.width,
      "height": asset->metadata.dimensions.height
    },
    pageMainTitle,
    pageSecondaryTitle,
    pageText,
    pageInfoTitle,
    pageInfoText,
    pageInfoTextPort,
    buttons
  }
`;

interface IndexProps {
  data: {
    sanityData: {
      topTitle?: string;
      pageImage: {
        alt: string;
        url: string;
        width?: number;
        height?: number;
      };
      buttons: {
        loginButton: string;
        registerButton: string;
      };
      pageInfoText: string;
      pageInfoTextPort: any;
      pageInfoTitle: string;
      pageMainTitle: string;
      pageSecondaryTitle: string;
      pageText: string;
    };
    setting: any;
  }
}

const Index: FunctionComponent<IndexProps> = ({ data }) => {
  //filter language data off router
  const router = useRouter();
  data = ParsePageLanguage(data, router.locale);
  
  // Destructuring
  const {
    buttons,
    topTitle,
    pageInfoTextPort,
    pageInfoTitle,
    pageMainTitle,
    pageSecondaryTitle,
    pageText,
    pageImage
  } = data.sanityData;
  const setting = data.setting;

  const { viewportWidth } = useViewPort();

  const navigate = (path) => {
    if (path) {
      // first parameter: pathname, second: as/alias
      router.push(`${path}`, `${path}`);
    }
  };

  //sanity portabletext styling/parsing
  const customComponents = {
    block: {
      normal: ({ children }: any) => (
        <Text as="p" size="small">
          <span className={styles.infoContentText}>{children}</span>
        </Text>
      )
    }
  };

  return (
    <>
      <Head>
        <title>{setting?.header?.headerTitle}</title>
      </Head>
      <main>
        <Header
          headerData={setting?.header}
          languages={true}
          hrefLanguages="/"
        />
        <article className={styles.index}>
          <div
            className={styles.introSection}
            style={{
              backgroundImage: `url(${pageImage.url})`
            }}
          >
            <div className={styles.introContent}>
              <div className={styles.titlesContainer}>
                <Text as="h1" customClassName={styles["topAndSecondTitle"]}>
                  {topTitle}
                </Text>

                <Text as="h1" customClassName={styles["mainTitle"]}>
                  {pageMainTitle}
                </Text>
                <Text as="h1" customClassName={styles["topAndSecondTitle"]}>
                  {pageSecondaryTitle}
                </Text>
              </div>

              <div className={styles.buttonsContainer}>
                <Button
                  variant="primary"
                  attribute="login"
                  size={viewportWidth < 768 ? "medium" : "large"}
                  onClick={() => navigate("/auth/login")}
                >
                  {buttons.loginButton}
                </Button>
                <Button
                  variant="secondary"
                  attribute="signup"
                  size={viewportWidth < 768 ? "xsmall" : "small"}
                  onClick={() => navigate("/auth/signup")}
                >
                  {buttons.registerButton}
                </Button>
              </div>
              <div className={styles.infoText}>
                <Text as="p">{pageText}</Text>
              </div>
            </div>
          </div>
          <div className={styles.infoSection}>
            <div className={styles.infoContent}>
              <Text as="h2">
                <div className={styles.infoTopText}>{pageInfoTitle}</div>
              </Text>
              <PortableText
                components={customComponents}
                value={pageInfoTextPort}
              />
            </div>
          </div>
        </article>
        <Footer footerData={setting?.footer} />
      </main>
    </>
  );
};

export async function getStaticProps({ preview = false }) {
  const sanityData = await getClient(preview).fetch(landingPageQuery);
  const setting = await getClient(preview).fetch(settingsQuery);
  
  return {
    props: {
      preview,
      data: {sanityData: sanityData[0], setting: setting[0]}
    },
    revalidate: 1
  };
}

export default Index;
