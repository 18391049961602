/**
 * Languages supported and available in the studio and at runtime.
 * isDefault=true marks default language if none is found or set.
 * Note: there's some ... differences on how to interpret this id with i18n vs other standard.
 * .id should -never- change, and should be considered a unique key. It may not be correct or ideal. This ID will be used in JSON/Sanity structure as a key.
 */
export const languageList = [
  { id: "da", title: "Danish" },
  { id: "de", title: "German" },
  { id: "en", title: "English" },
  { id: "es", title: "Spanish" },
  { id: "fi", title: "Finnish" },
  { id: "fr", title: "French" },
  { id: "it", title: "Italian" },
  { id: "lt", title: "Lithuanian" },
  { id: "nb", title: "Norwegian (Bokmål)", isDefault: true },
  { id: "pl", title: "Polish" },
  { id: "pt", title: "Portuguese" },
  { id: "sv", title: "Swedish" },
];

/**
 * Fetches the Sanity language list off SiteSettings, and generates compatible structure for languageFilter plugin (remapping to avoid issues).
 * Combines Sanity languageList with languages.js.languageList array.
 * This only works once per refresh - it isn't real time.
 * Currently this is quite similar (if not identical at this point) to languageList itself, but that could change (whence remapping/"translation" options).
 */
export async function GetLanguageFilterOffSanity(sanityClient) {
  const newSanityLanguageListRaw = await sanityClient.fetch(
    `*[_type in ["siteSettings"]].languageList`
  );
  const newSanityLanguageList = newSanityLanguageListRaw[0];
  let newLanguageFilter = [];
  try {
    if (newSanityLanguageList !== null) {
      for (let i = 0; i < newSanityLanguageList.length; i++) {
        let detectedLanguage = languageList.find(
          (language) => language.id === newSanityLanguageList[i].id
        );
        if (i === 0) detectedLanguage.isDefault = true;
        newLanguageFilter.push(detectedLanguage);
      }
    } else {
      newLanguageFilter.push(languageList[0]);
    }
  } catch (error) {
    //if anything changes that causes runtime error, default to first item
    newLanguageFilter.push(languageList[0]);
  }
  //console.log("GetLanguageFilterOffSanity=",newLanguageFilter, ", sanityClient=",sanityClient);

  return newLanguageFilter;
}

/**
 * Setter function for local settingsLanguages.
 * @param {*} newLanguages Array of language elements.
 */
export function SetSettingsLanguages(newLanguages) {
  settingsLanguages = newLanguages;
}

/**
 * Getter for local variable.
 * @todo  Is this needed anymore?
 */
export function GetSettingsLanguages() {
  return settingsLanguages;
}

/**
 * Sorts 2D array.
 * @returns Modifies input directly.
 */
function SortArrayModule2D(a, b) {
  const x = a.title;
  const y = b.title;
  if (x < y) return -1;
  if (x > y) return 1;
  return 0;
}

/**
 * Checks if value input is an array that has a matching element with _key that is a language id (en_gb).
 * If none is found, assumes value directly (no language for instance, or legacy).
 * @param valueData String, Object or Array.
 * @param languageID  Languages .id to check object for.
 * @returns String, object.
 */
export function GetLanguageValue(valueData, languageID) {
  let foundLanguageIndex = -1;
  //console.log("GetLanguageValue: valueData=",valueData,", languageID=",languageID,", typeof=",typeof valueData);
  if (typeof valueData === "object") {
    try {
      foundLanguageIndex = valueData.findIndex(
        (item) => item?._key === languageID
      );
    } catch (error) {
      //debugger;
    }
  }
  if (foundLanguageIndex >= 0) {
    return valueData[foundLanguageIndex].value;
  } else {
    return valueData;
  }
}

/**
 * Recursive returning a cloned object with Locale* values matching a specific language ID.
 * Useful for duplicating globally parsed JSON-data, and make use of language versions at runtime (opens up realtime changes).
 * @param valueData Object to traverse and modify.
 * @param languageID Languages .id to check for.
 * @returns Cloned input with language object arrays reduced to active language only.
 */
export function ReturnClonedLanguageObject(valueData, languageID) {
  let cloneValues = Array.isArray(valueData)
    ? Object.assign([], valueData)
    : Object.assign({}, valueData);

  if (typeof valueData === "object") {
    for (let key in cloneValues) {
      if (typeof cloneValues[key] === "object") {
        //check if key is a language array, which always is prefixed by _type=locale
        for (let i in cloneValues[key]) {
          //console.log("i=",i,"=",cloneValues[key][i], ", [key]=",cloneValues[key]);
          const currentObject = cloneValues[key][i];

          //locale-object detected?
          if (
            currentObject !== null &&
            String(currentObject?._type).indexOf("Locale") !== -1
          ) {
            /*if (
              Object.prototype.hasOwnProperty.call(currentObject, "_key") &&
              currentObject._key === languageID
            ) {
              cloneValues[key] = GetLanguageValue(cloneValues[key], languageID);
              break;
            }*/
            cloneValues[key] = GetLanguageValue(cloneValues[key], languageID);
            break;
          } else {
            //this can be a massive bottleneck if not handled with care
            //we're in effect calling the same objects over and over again (recursive)
            cloneValues[key] = ReturnClonedLanguageObject(
              cloneValues[key],
              languageID
            );

            //immediately break after to avoid recursive looping "per symbol"
            break;
          }
        }
      }
    }
  }
  //console.log("cloneValues=", cloneValues);
  return cloneValues;
}

/**
 * Takes Sanity data normally meant for page, parses it for language, and returns new data in legacy format.
 * @param {object} pageData Page data as {sanityData, setting}. We'll need this as one object so we can modify it, and return it language compiled.
 * @param {string} routerLocale Languages language-id.
 * @returns New page/Sanity data with Sanity default language, and selected router locale used as override if not blank.
 */
export function ParsePageLanguage(pageData, routerLocale) {
  let newData = pageData;

  if (pageData != null) {
    //get default language before parsing language data
    const defaultLanguage = pageData.setting?.languageList[0]?.id;

    //parse language, either using sanity default, or local default
    if (routerLocale !== "") {
      newData = ReturnClonedLanguageObject(pageData, routerLocale);
    } else {
      newData = ReturnClonedLanguageObject(pageData, defaultLanguage);
    }

    //just in case some languages are missing arrays causing runtime errors, default to default language
    newData = ReturnClonedLanguageObject(newData, defaultLanguage);
  }

  return newData;
}

//sort language list by default
//for actual languages, use its id not its index so order doesn't matter
languageList.sort(SortArrayModule2D);

/**
 * SiteSettings languages, parsed off Sanity at runtime. Not sorted/ordered, with [0] being the default language.
 */
export let settingsLanguages = [];
export const languageDefault = languageList.find(
  (language) => language.isDefault
);
